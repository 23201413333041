import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
import Footer from "../components/footer";
import Metatags from "../components/metatags";
import MarkdownEditor from "../components/markdown/application";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <Metatags title="Blog composer | May 7 | CSS Grid a day" description="An example of using CSS grid every day, from May 1–31st, 2019." thumbnail="https://cssgrid31.brett.cool/opengraph/may-7.png" url="https://cssgrid31.brett.cool" pathname="/may-7" mdxType="Metatags" />
    <MarkdownEditor mdxType="MarkdownEditor" />
    <Footer date={7} prev={true} next={true} mdxType="Footer">
      <p>{`A demo blog admin built with CSS Grid, and rendering inputted markdown with Remarkable (blatantly lifted from an example on `}<a parentName="p" {...{
          "href": "https://reactjs.org#a-component-using-external-plugins"
        }}>{`Reactjs.org`}</a>{`).`}</p>
      <p>{`I’ve been a `}<a parentName="p" {...{
          "href": "https://daringfireball.net/projects/markdown/",
          "title": "Markdown by John Gruber"
        }}>{`Markdown`}</a>{` fan for a long time. I love how readable it is in plaintext, especially when using reference style links.`}</p>
      <p>{`Plus the tooling around it is great. Writing `}<a parentName="p" {...{
          "href": "https://github.com/BrettJay/brett.cool/blob/master/source/blog/2019-05-02-css-grid-a-day.md.erb",
          "title": "Source for reference blog post on Github"
        }}>{`blog posts`}</a>{` is so frictionless with static site generators like Jekyll, Middleman, and Gatsby working great with Markdown out of the box.`}</p>
      <p><a parentName="p" {...{
          "href": "https://github.com/BrettJay/css-grid-a-day/tree/master/src/pages"
        }}>{`I’m using`}</a>{` `}<a parentName="p" {...{
          "href": "https://mdxjs.com/"
        }}>{`mdx`}</a>{` on this project, and I’m quite liking it so far.`}</p>
    </Footer>

    </MDXLayout>;
}
MDXContent.isMDXComponent = true;
      